import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from '../../utils/baseQueryFn';


export const roleApi = createApi({
  reducerPath: 'roleApi',
  baseQuery,
  endpoints: (builder) => ({
    getRole: builder.query({
      query: ({ page, limit, search }) => {
        const queryParams = new URLSearchParams({
          page,
          limit,
        });

        if (search) {
          queryParams.append('search', search);
        }

        const apiUrl = `main/v1/roles?${queryParams.toString()}`;

        return {
          url: apiUrl,
          method: 'GET',
        };
      },
      providesTags: ['roles'],
      transformResponse: (response) => response,
    }),
    getRoleDetail: builder.query({
      query: (id) => ({
        url: `main/v1/roles/${id}`,
        method: 'GET',
      }),
      providesTags: ['roles'],
      transformResponse: (response) => response,
    }),
    createRole: builder.mutation({
      query: (body) => ({
        url: `main/v1/roles/new`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
      invalidatesTags: ['roles'],
    }),
    updateRole: builder.mutation({
      query: (body) => ({
        url: `main/v1/roles/edit`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
      invalidatesTags: ['roles'],
    }),
    deleteRole: builder.mutation({
      query: (body) => ({
        url: `main/v1/roles/delete`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
      invalidatesTags: ['roles'],
    }),
  }),
});

export const { useGetRoleQuery, useGetRoleDetailQuery, useCreateRoleMutation, useUpdateRoleMutation, useDeleteRoleMutation } = roleApi;
