import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../utils/baseQueryFn';

export const subcontractorApi = createApi({
  reducerPath: 'SubcontractorApi',
  baseQuery,
  endpoints: (builder) => ({
    getSubcontractor: builder.query({
      query: ({ page, limit, search }) => {
        const queryParams = new URLSearchParams({
          page,
          limit,
        });

        if (search) {
          queryParams.append('search', search);
        }

        const apiUrl = `main/v1/subcontractors?${queryParams.toString()}`;

        return {
          url: apiUrl,
          method: 'GET',
        };
      },
      transformResponse: (response) => response,
      providesTags: ['subcontractor'],
    }),
    getAllSubcontractor: builder.mutation({
      query: ({ page, limit, search }) => {
        const queryParams = new URLSearchParams({
          page,
          limit,
        });

        if (search) {
          queryParams.append('search', search);
        }

        const apiUrl = `main/v1/subcontractors?${queryParams.toString()}`;

        return {
          url: apiUrl,
          method: 'GET',
        };
      },
      transformResponse: (response) => response,
      providesTags: ['subcontractor'],
    }),
    createSubcontractor: builder.mutation({
      query: (body) => ({
        url: `main/v1/subcontractors/new`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['subcontractor'],
    }),
    updateSubcontractor: builder.mutation({
      query: (body) => ({
        url: `main/v1/subcontractors/edit`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['subcontractor'],
    }),
    getSubcontractorByID: builder.query({
      query: (id) => ({
        url: `main/v1/subcontractors/${id}`,
        method: 'GET',
      }),
      transformResponse: (response) => response,
      providesTags: ['subcontractor'],
    }),
    // checkSubcontractorId: builder.mutation({
    //   query: (body) => ({
    //     url: `main/v1/Subcontractors/check-subcontractor-id`,
    //     method: 'POST',
    //     body,
    //   }),
    // }),
    deleteSubcontractor: builder.mutation({
      query: (body) => ({
        url: `main/v1/subcontractors/delete`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['subcontractor'],
    }),
  }),
});

export const {
  useGetSubcontractorQuery,
  useCreateSubcontractorMutation,
  useUpdateSubcontractorMutation,
  useGetSubcontractorByIDQuery,
  // useCheckSubcontractorIdMutation,
  useGetAllSubcontractorMutation,
  useDeleteSubcontractorMutation,
} = subcontractorApi;
