import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../utils/baseQueryFn';

export const unitsApi = createApi({
  reducerPath: 'unitsApi',
  baseQuery,
  endpoints: (builder) => ({
    getUnits: builder.query({
      query: ({ page, limit, search }) => {
        const queryParams = new URLSearchParams({
          page,
          limit,
        });

        if (search) {
          queryParams.append('search', search);
        }

        const apiUrl = `main/v1/units?${queryParams.toString()}`;

        return {
          url: apiUrl,
          method: 'GET',
        };
      },
      providesTags: ['units'],
      transformResponse: (response) => response,
    }),
    getUnitsById: builder.query({
      query: (id) => ({
        url: `main/v1/units/${id}`,
        method: 'GET',
      }),
      providesTags: ['units'],
      transformResponse: (response) => response,
    }),
    updateUnits: builder.mutation({
      query: (body) => ({
        url: `main/v1/units/edit`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
      invalidatesTags: ['units'],
    }),
    createUnits: builder.mutation({
      query: (body) => ({
        url: `main/v1/units/new`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
      invalidatesTags: ['units'],
    }),
    deleteUnits: builder.mutation({
      query: (body) => ({
        url: `main/v1/units/delete`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
      invalidatesTags: ['units'],
    }),
  }),
});

export const {
  useGetUnitsQuery,
  useGetUnitsByIdQuery,
  useUpdateUnitsMutation,
  useCreateUnitsMutation,
  useDeleteUnitsMutation,
} = unitsApi;
