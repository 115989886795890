import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from '../../utils/baseQueryFn';


export const labourerPaymentApi = createApi({
    reducerPath: "labourerPaymentApi",
    baseQuery,
    endpoints: (builder) => ({
        getLabourPaymentList: builder.query({
            query: ({ page, limit, teamId, startDate, endDate, status }) => {

                const queryParams = new URLSearchParams({
                    page,
                    limit,
                });

                if (teamId.length > 0) {
                    queryParams.append('team_ids', JSON.stringify(teamId));
                }

                if (startDate) {
                    queryParams.append('start_date', startDate);
                }

                if (endDate) {
                    queryParams.append('end_date', endDate);
                }

                if (status) {
                    queryParams.append('status', status);
                }

                const apiUrl = `main/v1/laborers/pay-slip?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            providesTags: ["labourer-payment"],
            transformResponse: (response) => response,
        }),
        getAllLabourPaymentList: builder.mutation({
            query: ({ page, limit, teamId, startDate, endDate, status }) => {

                const queryParams = new URLSearchParams({
                    page,
                    limit,
                });

                if (teamId.length > 0) {
                    queryParams.append('team_ids', JSON.stringify(teamId));
                }

                if (startDate) {
                    queryParams.append('start_date', startDate);
                }

                if (endDate) {
                    queryParams.append('end_date', endDate);
                }

                if (status) {
                    queryParams.append('status', status);
                }

                const apiUrl = `main/v1/laborers/pay-slip?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            providesTags: ["labourer-payment"],
            transformResponse: (response) => response,
        }),
        markAsPaidLabourer: builder.mutation({
            query: (body) => ({
                url: `main/v1/laborers/pay-slip/mark-as-paid`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body
            }),
            invalidatesTags: ["labourer-payment"],
        }),
        getLabourerPaySlipData: builder.mutation({
            query: (body) => ({
                url: `main/v1/laborers/pay-slip/data`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body
            }),
            transformResponse: (response) => response,
            providesTags: ["labourer"],
        }),
        createLabourerBillsettlementSlip: builder.mutation({
            query: (body) => ({
                url: `main/v1/laborers/pay-slip/new`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body
            }),
            invalidatesTags: ["labourer-payment"],
        }),
        getLabourerPaySlipByID: builder.query({
            query: (id) => ({
                url: `main/v1/laborers/pay-slip/${id}`,
                method: "GET",
            }),
            transformResponse: (response) => response,
            providesTags: ["labourer", "labourer-payment"],
        }),
        editLabourerBillsettlementSlip: builder.mutation({
            query: (body) => ({
                url: `main/v1/laborers/pay-slip/edit`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body
            }),
            invalidatesTags: ["labourer", "labourer-payment"],
        }),
    }),
});

export const { useGetLabourPaymentListQuery, useMarkAsPaidLabourerMutation, useGetLabourerPaySlipDataMutation, useCreateLabourerBillsettlementSlipMutation, useGetLabourerPaySlipByIDQuery, useEditLabourerBillsettlementSlipMutation,useGetAllLabourPaymentListMutation } = labourerPaymentApi;
