import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from '../../utils/baseQueryFn';


export const purchaseApi = createApi({
    reducerPath: "purchaseApi",
    baseQuery,
    endpoints: (builder) => ({
        getPurchaseList: builder.query({
            query: ({ page, limit, search, status, shops, startDate, endDate }) => {

                const queryParams = new URLSearchParams({
                    page,
                    limit,
                });

                if (search) {
                    queryParams.append('search', search);
                }

                if (status) {
                    queryParams.append('status', status);
                }

                if (shops.length > 0) {
                    queryParams.append('shop_ids', JSON.stringify(shops));
                }

                if (startDate) {
                    queryParams.append('start_date', startDate);
                }

                if (endDate) {
                    queryParams.append('end_date', endDate);
                }

                const apiUrl = `main/v1/shops/purchase-orders?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
        }),
        getAllPurchaseList: builder.mutation({
            query: ({ page, limit, search, status, shops, startDate, endDate }) => {

                const queryParams = new URLSearchParams({
                    page,
                    limit,
                });

                if (search) {
                    queryParams.append('search', search);
                }

                if (status) {
                    queryParams.append('status', status);
                }

                if (shops.length > 0) {
                    queryParams.append('shop_ids', JSON.stringify(shops));
                }

                if (startDate) {
                    queryParams.append('start_date', startDate);
                }

                if (endDate) {
                    queryParams.append('end_date', endDate);
                }

                const apiUrl = `main/v1/shops/purchase-orders?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
        }),
    }),
});

export const { useGetPurchaseListQuery, useGetAllPurchaseListMutation } = purchaseApi;
