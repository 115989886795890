import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


export const authApi = createApi({
    reducerPath: "authApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_URL,
    }),
    endpoints: (builder) => ({
        verifyPhoneNumber: builder.mutation({
            query: (body) => ({
                url: `auth/v1/users/send-otp`,
                method: "POST",
                body
            }),
            transformResponse: (response) => response,
        }),
        verifyOtp: builder.mutation({
            query: (body) => ({
                url: `auth/v1/users/check-otp`,
                method: "POST",
                body
            }),
            transformResponse: (response) => response,
        }),
    }),
});

export const { useVerifyPhoneNumberMutation,useVerifyOtpMutation } = authApi;
