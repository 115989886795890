import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../utils/baseQueryFn';

export const staffApi = createApi({
  reducerPath: 'staffApi',
  baseQuery,
  endpoints: (builder) => ({
    getStaff: builder.query({
      query: ({ page, limit, search, status }) => {
        const queryParams = new URLSearchParams({
          page,
          limit,
        });

        if (search) {
          queryParams.append('search', search);
        }

                if (status) {
                    queryParams.append('status', status);
                }


                const apiUrl = `main/v1/staffs?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["staff"],
        }),
        getAllStaff: builder.mutation({
            query: ({ page, limit, search, status }) => {

                const queryParams = new URLSearchParams({
                    page,
                    limit,
                });

                if (search) {
                    queryParams.append('search', search);
                }

                if (status) {
                    queryParams.append('status', status);
                }


        const apiUrl = `main/v1/staffs?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["staff"],
        }),
        createStaff: builder.mutation({
            query: (body) => ({
                url: `main/v1/staffs/new`,
                method: "POST",
                body
            }),
            invalidatesTags: ["staff"],
        }),
        updateStaff: builder.mutation({
            query: (body) => ({
                url: `main/v1/staffs/edit`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body
            }),
            invalidatesTags: ["staff"],
        }),
        getStaffByID: builder.query({
            query: (id) => ({
                url: `main/v1/staffs/${id}`,
                method: "GET",
            }),
            transformResponse: (response) => response,
            providesTags: ["staff"],
        }),
        getAttendance: builder.query({
            query: ({ page, limit, staff, startDate, endDate }) => {

                const queryParams = new URLSearchParams({
                    page,
                    limit,
                });

                if (staff.length > 0) {
                    queryParams.append('staff_ids', JSON.stringify(staff));
                }

                if (startDate) {
                    queryParams.append('start_date', startDate);
                }

                if (endDate) {
                    queryParams.append('end_date', endDate);
                }

                const apiUrl = `main/v1/staffs/attendance?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            providesTags: ["attendace"],
            transformResponse: (response) => response,
        }),
        getAllAttendance: builder.mutation({
            query: ({ page, limit, staff, startDate, endDate }) => {

                const queryParams = new URLSearchParams({
                    page,
                    limit,
                });

        if (staff.length > 0) {
          queryParams.append('staff_ids', JSON.stringify(staff));
        }

        if (startDate) {
          queryParams.append('start_date', startDate);
        }

        if (endDate) {
          queryParams.append('end_date', endDate);
        }

        const apiUrl = `main/v1/staffs/attendance?${queryParams.toString()}`;

        return {
          url: apiUrl,
          method: 'GET',
        };
      },
      providesTags: ['attendace'],
      transformResponse: (response) => response,
    }),
    checkEmployeeId: builder.mutation({
      query: (body) => ({
        url: `main/v1/staffs/check-employee-id`,
        method: 'POST',
        body,
      }),
    }),
    clockIn: builder.mutation({
      query: (body) => ({
        url: `main/v1/staffs/attendance/clock-in`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['attendace'],
    }),
    clockOut: builder.mutation({
      query: (body) => ({
        url: `main/v1/staffs/attendance/clock-out`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['attendace'],
    }),
    deleteStaff: builder.mutation({
      query: (body) => ({
        url: `main/v1/staffs/delete`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['staff'],
    }),
  }),
});

export const {
  useGetStaffQuery,
  useCreateStaffMutation,
  useUpdateStaffMutation,
  useGetStaffByIDQuery,
  useGetAttendanceQuery,
  useCheckEmployeeIdMutation,
  useClockInMutation,
  useClockOutMutation, useGetAllStaffMutation,useGetAllAttendanceMutation,
  useDeleteStaffMutation,
} = staffApi;
