import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../utils/baseQueryFn';

export const materialsApi = createApi({
  reducerPath: 'materialsApi',
  baseQuery,
  endpoints: (builder) => ({
    getMaterials: builder.query({
      query: ({ page, limit, search }) => {
        const queryParams = new URLSearchParams({
          page,
          limit,
        });

        if (search) {
          queryParams.append('search', search);
        }

        const apiUrl = `main/v1/materials?${queryParams.toString()}`;

        return {
          url: apiUrl,
          method: 'GET',
        };
      },
      providesTags: ['materials'],
      transformResponse: (response) => response,
    }),
    getMaterialsById: builder.query({
      query: (id) => ({
        url: `main/v1/materials/${id}`,
        method: 'GET',
      }),
      providesTags: ['materials'],
      transformResponse: (response) => response,
    }),
    updateMaterials: builder.mutation({
      query: (body) => ({
        url: `main/v1/materials/edit`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
      invalidatesTags: ['materials'],
    }),
    createMaterials: builder.mutation({
      query: (body) => ({
        url: `main/v1/materials/new`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
      invalidatesTags: ['materials'],
    }),
    deleteMaterials: builder.mutation({
      query: (body) => ({
        url: `main/v1/materials/delete`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
      invalidatesTags: ['materials'],
    }),
  }),
});

export const {
  useGetMaterialsQuery,
  useGetMaterialsByIdQuery,
  useUpdateMaterialsMutation,
  useCreateMaterialsMutation,
  useDeleteMaterialsMutation,
} = materialsApi;
