import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../utils/baseQueryFn';

export const taskTypesApi = createApi({
  reducerPath: 'taskTypesApi',
  baseQuery,
  endpoints: (builder) => ({
    getTaskTypes: builder.query({
      query: ({ page, limit, search }) => {
        const queryParams = new URLSearchParams({
          page,
          limit,
        });

        if (search) {
          queryParams.append('search', search);
        }

        const apiUrl = `main/v1/task-types?${queryParams.toString()}`;

        return {
          url: apiUrl,
          method: 'GET',
        };
      },
      providesTags: ['task-types'],
      transformResponse: (response) => response,
    }),
    getTaskTypesById: builder.query({
      query: (id) => ({
        url: `main/v1/task-types/${id}`,
        method: 'GET',
      }),
      providesTags: ['task-types'],
      transformResponse: (response) => response,
    }),
    updateTaskTypes: builder.mutation({
      query: (body) => ({
        url: `main/v1/task-types/edit`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
      invalidatesTags: ['task-types'],
    }),
    createTaskTypes: builder.mutation({
      query: (body) => ({
        url: `main/v1/task-types/new`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
      invalidatesTags: ['task-types'],
    }),
    deleteTaskTypes: builder.mutation({
      query: (body) => ({
        url: `main/v1/task-types/delete`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
      invalidatesTags: ['task-types'],
    }),
  }),
});

export const {
  useGetTaskTypesQuery,
  useGetTaskTypesByIdQuery,
  useUpdateTaskTypesMutation,
  useCreateTaskTypesMutation,
  useDeleteTaskTypesMutation,
} = taskTypesApi;
