import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
//
import { useVerifyOtpMutation, useVerifyPhoneNumberMutation } from 'src/store/auth/auth-api';
import { AuthContext } from './auth-context';
import { setSession } from './utils';
// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';
const STORAGE_USER_KEY = 'user';

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [verifyPhoneNumber] = useVerifyPhoneNumberMutation();
  const [verifyOtp] = useVerifyOtpMutation();

  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem(STORAGE_KEY);
      const user = JSON.parse(localStorage.getItem(STORAGE_USER_KEY));

      if (accessToken && user) {
        dispatch({
          type: 'INITIAL',
          payload: {
            user,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // SENDOTP
  const sendOtp = useCallback(async (userName, phoneNumber) => {
    const body = { username: userName, phone_number: phoneNumber };
    await verifyPhoneNumber(body).unwrap();
  }, [verifyPhoneNumber]);

  const verifyOtpCode = useCallback(async (otp, phoneNumber) => {
    const body = { otp, phone_number: phoneNumber };
    const response = await verifyOtp(body).unwrap();

    const { token, ...user } = response;

    setSession(token);

    localStorage.setItem(STORAGE_USER_KEY, JSON.stringify(user));

    dispatch({
      type: 'LOGIN',
      payload: { user },
    });

  }, [verifyOtp]);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    localStorage.removeItem(STORAGE_USER_KEY);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // UPDATE USER PERMISSION
  const updateUserPermission = useCallback(async (permissions) => {
    const user = JSON.parse(localStorage.getItem(STORAGE_USER_KEY));
    user.permissions = permissions;
    localStorage.setItem(STORAGE_USER_KEY, JSON.stringify(user));
    dispatch({
      type: 'INITIAL',
      payload: {
        user,
      },
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      sendOtp,
      verifyOtpCode,
      logout,
      updateUserPermission,
    }),
    [logout, sendOtp, state.user, status, verifyOtpCode, updateUserPermission]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
