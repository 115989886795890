import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from '../../utils/baseQueryFn';


export const shopsApi = createApi({
    reducerPath: "shopsApi",
    baseQuery,
    endpoints: (builder) => ({
        getShops: builder.query({
            query: ({ page, limit, search }) => {

                const queryParams = new URLSearchParams({
                    page,
                    limit,
                });

                if (search) {
                    queryParams.append('search', search);
                }

                const apiUrl = `main/v1/shops?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["shop"],
        }),
        getAllShops: builder.mutation({
            query: ({ page, limit, search }) => {

                const queryParams = new URLSearchParams({
                    page,
                    limit,
                });

        if (search) {
          queryParams.append('search', search);
        }

        const apiUrl = `main/v1/shops?${queryParams.toString()}`;

        return {
          url: apiUrl,
          method: 'GET',
        };
      },
      transformResponse: (response) => response,
      providesTags: ['shop'],
    }),
    createShop: builder.mutation({
      query: (body) => ({
        url: `main/v1/shops/new`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['shop'],
    }),
    updateShop: builder.mutation({
      query: (body) => ({
        url: `main/v1/shops/edit`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
      invalidatesTags: ['shop'],
    }),
    getShopByID: builder.query({
      query: (id) => ({
        url: `main/v1/shops/${id}`,
        method: 'GET',
      }),
      transformResponse: (response) => response,
      providesTags: ['shop'],
    }),
    deleteShop: builder.mutation({
      query: (body) => ({
        url: `main/v1/shops/delete`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['shop'],
    }),
  }),
});

export const { useGetShopsQuery, useCreateShopMutation, useGetShopByIDQuery, useUpdateShopMutation, useGetAllShopsMutation, useDeleteShopMutation } = shopsApi;
