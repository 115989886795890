import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../utils/baseQueryFn';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery,
  endpoints: (builder) => ({
    getUser: builder.query({
      query: ({ page, limit, search, status, role }) => {
        const queryParams = new URLSearchParams({
          page,
          limit,
        });

        if (search) {
          queryParams.append('search', search);
        }

        if (status) {
          queryParams.append('status', status);
        }

                if (role.length > 0) {
                    queryParams.append('role_ids', JSON.stringify(role));
                }


                const apiUrl = `main/v1/users?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["user"],
        }),
        getAllUser: builder.mutation({
            query: ({ page, limit, search, status, role }) => {

                const queryParams = new URLSearchParams({
                    page,
                    limit,
                });

                if (search) {
                    queryParams.append('search', search);
                }

                if (status) {
                    queryParams.append('status', status);
                }

                if (role.length > 0) {
                    queryParams.append('role_ids', JSON.stringify(role));
                }


        const apiUrl = `main/v1/users?${queryParams.toString()}`;

        return {
          url: apiUrl,
          method: 'GET',
        };
      },
      transformResponse: (response) => response,
      providesTags: ['user'],
    }),
    checkUserName: builder.mutation({
      query: (body) => ({
        url: `main/v1/users/check-username`,
        method: 'POST',
        body,
      }),
    }),
    createNewUser: builder.mutation({
      query: (body) => ({
        url: `main/v1/users/new`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['user'],
    }),
    getUserByID: builder.query({
      query: (id) => ({
        url: `main/v1/users/${id}`,
        method: 'GET',
      }),
      transformResponse: (response) => response,
      providesTags: ['user'],
    }),
    createNonStaffUser: builder.mutation({
      query: (body) => ({
        url: `main/v1/non-staff/new`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['user'],
    }),
    editUser: builder.mutation({
      query: (body) => ({
        url: `main/v1/users/edit`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['user'],
    }),
    deleteUser: builder.mutation({
      query: (body) => ({
        url: `main/v1/users/delete`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['user'],
    }),
  }),
});

export const {
  useGetUserQuery,
  useCheckUserNameMutation,
  useCreateNewUserMutation,
  useGetUserByIDQuery,
  useCreateNonStaffUserMutation,
  useEditUserMutation,useGetAllUserMutation,
  useDeleteNonStaffUserMutation,
  useDeleteUserMutation,
} = userApi;
