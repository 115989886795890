import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from '../../utils/baseQueryFn';


export const paymentApi = createApi({
    reducerPath: "paymentApi",
    baseQuery,
    endpoints: (builder) => ({
        getPaymentList: builder.query({
            query: ({ page, limit, search, shops, startDate, endDate, status }) => {

                const queryParams = new URLSearchParams({
                    page,
                    limit,
                });

                if (search) {
                    queryParams.append('search', search);
                }

                if (shops.length > 0) {
                    queryParams.append('shop_ids', JSON.stringify(shops));
                }

                if (startDate) {
                    queryParams.append('start_date', startDate);
                }

                if (endDate) {
                    queryParams.append('end_date', endDate);
                }

                if (status) {
                    queryParams.append('status', status);
                }

                const apiUrl = `main/v1/shops/payments?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            providesTags: ["payment"],
            transformResponse: (response) => response,
        }),
        getAllPaymentList: builder.mutation({
            query: ({ page, limit, search, shops, startDate, endDate, status }) => {

                const queryParams = new URLSearchParams({
                    page,
                    limit,
                });

                if (search) {
                    queryParams.append('search', search);
                }

                if (shops.length > 0) {
                    queryParams.append('shop_ids', JSON.stringify(shops));
                }

                if (startDate) {
                    queryParams.append('start_date', startDate);
                }

                if (endDate) {
                    queryParams.append('end_date', endDate);
                }

                if (status) {
                    queryParams.append('status', status);
                }

                const apiUrl = `main/v1/shops/payments?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            providesTags: ["payment"],
            transformResponse: (response) => response,
        }),
        markAsPaidShop: builder.mutation({
            query: (body) => ({
                url: `main/v1/shops/payments/mark-as-paid`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body
            }),
            invalidatesTags: ["payment"],
        }),
        getCreateBillsettlementSlip: builder.mutation({
            query: (body) => ({
                url: `main/v1/shops/payments/data`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body
            }),
            transformResponse: (response) => response,
            providesTags: ["shop"],
        }),
        createBillsettlementSlip: builder.mutation({
            query: (body) => ({
                url: `main/v1/shops/payments/new`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body
            }),
            invalidatesTags: ["shop", "payment"],
        }),
        getShopPaySlipByID: builder.query({
            query: (id) => ({
                url: `main/v1/shops/payments/${id}`,
                method: "GET",
            }),
            transformResponse: (response) => response,
            providesTags: ["shop", "payment"],
        }),
        editBillsettlementSlip: builder.mutation({
            query: (body) => ({
                url: `main/v1/shops/payments/edit`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body
            }),
            invalidatesTags: ["payment"],
        }),
    }),
});

export const { useGetPaymentListQuery, useMarkAsPaidShopMutation, useGetCreateBillsettlementSlipMutation, useCreateBillsettlementSlipMutation, useGetShopPaySlipByIDQuery, useEditBillsettlementSlipMutation,useGetAllPaymentListMutation } = paymentApi;
