import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../utils/baseQueryFn';

export const utilsApi = createApi({
  reducerPath: 'utilsApi',
  baseQuery,
  endpoints: (builder) => ({
    getUtils: builder.query({
      query: ({ page, limit, search }) => {
        const queryParams = new URLSearchParams({
          page,
          limit,
        });

        if (search) {
          queryParams.append('search', search);
        }

        const apiUrl = `main/v1/utils?${queryParams.toString()}`;

        return {
          url: apiUrl,
          method: 'GET',
        };
      },
      providesTags: ['utils'],
      transformResponse: (response) => response,
    }),
    getUtilsById: builder.query({
      query: (id) => ({
        url: `main/v1/utils/${id}`,
        method: 'GET',
      }),
      providesTags: ['utils'],
      transformResponse: (response) => response,
    }),
    updateUtils: builder.mutation({
      query: (body) => ({
        url: `main/v1/utils/edit`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
      invalidatesTags: ['utils'],
    }),
    deleteUtils: builder.mutation({
      query: (body) => ({
        url: `main/v1/utils/delete`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
      invalidatesTags: ['utils'],
    }),
  }),
});

export const {
  useGetUtilsQuery,
  useGetUtilsByIdQuery,
  useUpdateUtilsMutation,
  useDeleteUtilsMutation,
} = utilsApi;
