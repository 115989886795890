import { Navigate, useRoutes } from 'react-router-dom';
// config
import { useEffect } from 'react';
import { PATH_AFTER_LOGIN } from 'src/config-global';
//
import { useAuthContext } from 'src/auth/hooks';
import { useCheckPermissionMutation } from 'src/store/data/data-api';
import { mainRoutes } from './main';

import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';
import { paths } from '../paths';

// ----------------------------------------------------------------------

export default function Router() {
  const { user, updateUserPermission, authenticated } = useAuthContext();

  const [checkPermission] = useCheckPermissionMutation();


  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const response = await checkPermission().unwrap();
        if (response) {
          updateUserPermission(response.permissions);
        }
      } catch (error) {
        console.error('Failed to fetch permissions:', error);
      }
    };

    if (authenticated) {
      fetchPermissions();
    }

  }, [authenticated, checkPermission, updateUserPermission]);

  return useRoutes([
    {
      path: '/',
      element: user ? <Navigate to={PATH_AFTER_LOGIN} replace /> : <Navigate to={paths.auth.login} replace />,
    },

    // Auth routes
    ...authRoutes,

    // Dashboard routes (only if user is authenticated)
    ...(user ? dashboardRoutes(user) : []),

    // Main routes
    ...mainRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
