import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import Iconify from 'src/components/iconify';
import { hasPermission } from 'src/utils/permissions';
import { useAuthContext } from 'src/auth/hooks';

// ----------------------------------------------------------------------

const icon = (name) => (
  <Iconify icon={name} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  staff: icon('ic:baseline-people'),
  client: icon('ic:baseline-groups'),
  subcontractor: icon('mdi:home-user'),
  sites: icon('ic:round-home-work'),
  shops: icon('ic:baseline-shopping-cart'),
  user: icon('mdi:user'),
  roles: icon('mdi:account-key'),
  activity_log: icon('ic:baseline-history'),
  utils: icon('ic:baseline-settings-suggest'),
  overview: icon('clarity:analytics-solid'),
};

// ----------------------------------------------------------------------

export function useNavData() {

  const { user } = useAuthContext();

  const data = useMemo(
    () => [
      {
        items: [
          { title: 'Overview', path: paths.dashboard.root, icon: ICONS.overview },
          hasPermission(user, 'Manage Staff', 'Read') && {
            title: 'Staff',
            path: paths.dashboard.staff.root,
            icon: ICONS.staff,
            children: [
              { title: 'Staff List', path: paths.dashboard.staff.root },
              hasPermission(user, 'Staff Attendance', 'Read') && { title: 'Attendance', path: paths.dashboard.staff.attendance },
              hasPermission(user, 'Staff Salary', 'Read') && { title: 'Salary', path: paths.dashboard.staff.salary },
            ].filter(Boolean),
          },
          hasPermission(user, 'Laborers', 'Read') && {
            title: 'Labourers',
            path: paths.dashboard.labourer.root,
            icon: ICONS.user,
            children: [
              { title: 'Labourer List', path: paths.dashboard.labourer.root },
              hasPermission(user, 'Laborers Work History', 'Read') && { title: 'Work History', path: paths.dashboard.labourer.workhistory },
              hasPermission(user, 'Laborers Teams', 'Read') && { title: 'Teams', path: paths.dashboard.labourer.teams },
              hasPermission(user, 'Laborers Payments', 'Read') && { title: 'Payments', path: paths.dashboard.labourer.paymentHistory },
              hasPermission(user, 'Laborers Assignments', 'Read') && { title: 'Assignments', path: paths.dashboard.labourer.assignments },
            ].filter(Boolean),
          },
          hasPermission(user, 'Subcontractors', 'Read') &&
          {
            title: 'Subcontractors',
            path: paths.dashboard.subcontractor.root,
            icon: ICONS.subcontractor,
          },
          hasPermission(user, 'Clients', 'Read') && {
            title: 'Clients',
            path: paths.dashboard.client.root,
            icon: ICONS.client,
          },
          hasPermission(user, 'Sites', 'Read') && {
            title: 'Sites',
            path: paths.dashboard.sites.root,
            icon: ICONS.sites,
          },
          hasPermission(user, 'Shops', 'Read') && {
            title: 'Shops',
            path: paths.dashboard.Shops.root,
            icon: ICONS.shops,
            children: [
              { title: 'Shop List', path: paths.dashboard.Shops.root },
              hasPermission(user, 'Shops Purchase History', 'Read') && { title: 'Purchase History', path: paths.dashboard.Shops.purchaseHistory },
              hasPermission(user, 'Shops Payments', 'Read') && { title: 'Payment History', path: paths.dashboard.Shops.paymentHistory },
            ].filter(Boolean),
          },
          hasPermission(user, 'Users', 'Read') && {
            title: 'Users',
            path: paths.dashboard.user.root,
            icon: ICONS.user,
          },
          hasPermission(user, 'Roles', 'Read') && {
            title: 'Roles',
            path: paths.dashboard.roles.root,
            icon: ICONS.roles,
          },
          hasPermission(user, 'Activity Log', 'Read') && {
            title: 'Activity Log',
            path: paths.dashboard.activityLogs,
            icon: ICONS.activity_log,
          },
          {
            title: 'Utils',
            path: paths.dashboard.utils.root,
            icon: ICONS.utils,
            children: [
              hasPermission(user, 'Values', 'Read') && { title: 'Values', path: paths.dashboard.utils.values },
              hasPermission(user, 'Units', 'Read') && { title: 'Units', path: paths.dashboard.utils.units },
              hasPermission(user, 'Materials', 'Read') && { title: 'Materials', path: paths.dashboard.utils.materials },
              hasPermission(user, 'Task Types', 'Read') && { title: 'Task Types', path: paths.dashboard.utils.taskTypes },
            ].filter(Boolean),
          },
        ].filter(Boolean),
      },
    ],
    [user]
  );

  return data;
}
