import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from '../../utils/baseQueryFn';


export const labourerApi = createApi({
    reducerPath: "labourerApi",
    baseQuery,
    endpoints: (builder) => ({
        getLabourer: builder.query({
            query: ({ page, limit, search, status }) => {

                const queryParams = new URLSearchParams({
                    page,
                    limit,
                });

                if (search) {
                    queryParams.append('search', search);
                }

                if (status) {
                    queryParams.append('status', status);
                }

                const apiUrl = `main/v1/laborers?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["labourer"],
        }),
        getAllLabourer: builder.mutation({
            query: ({ page, limit, search, status }) => {

                const queryParams = new URLSearchParams({
                    page,
                    limit,
                });

        if (search) {
          queryParams.append('search', search);
        }

        if (status) {
          queryParams.append('status', status);
        }

        const apiUrl = `main/v1/laborers?${queryParams.toString()}`;

        return {
          url: apiUrl,
          method: 'GET',
        };
      },
      transformResponse: (response) => response,
      providesTags: ['labourer'],
    }),
    createLabourer: builder.mutation({
      query: (body) => ({
        url: `main/v1/laborers/new`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['labourer'],
    }),
    updateLabourer: builder.mutation({
      query: (body) => ({
        url: `main/v1/laborers/edit`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
      invalidatesTags: ['labourer'],
    }),
    getLabourerByID: builder.query({
      query: (id) => ({
        url: `main/v1/laborers/${id}`,
        method: 'GET',
      }),
      transformResponse: (response) => response,
      providesTags: ['labourer'],
    }),
    getLabourersWorkHistory: builder.query({
      query: ({ page, limit, status, teamLeads, laborers, startDate, endDate }) => {

                const queryParams = new URLSearchParams({
                    page,
                    limit,
                });

                if (status) {
                    queryParams.append('payment_status', status);
                }

                if (teamLeads.length > 0) {
                    queryParams.append('team_ids', JSON.stringify(teamLeads));
                }

                if (laborers.length > 0) {
                    queryParams.append('laborer_ids', JSON.stringify(laborers));
                }

                if (startDate) {
                    queryParams.append('start_date', startDate);
                }

                if (endDate) {
                    queryParams.append('end_date', endDate);
                }

                const apiUrl = `main/v1/laborers/work-history?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["labourer"],
        }),
        getAllLabourersWorkHistory: builder.mutation({
            query: ({ page, limit, status, teamLeads, laborers, startDate, endDate }) => {
        const queryParams = new URLSearchParams({
          page,
          limit,
        });

        if (status) {
          queryParams.append('payment_status', status);
        }

        if (teamLeads.length > 0) {
          queryParams.append('team_ids', JSON.stringify(teamLeads));
        }

        if (laborers.length > 0) {
          queryParams.append('laborer_ids', JSON.stringify(laborers));
        }

        if (startDate) {
          queryParams.append('start_date', startDate);
        }

        if (endDate) {
          queryParams.append('end_date', endDate);
        }

        const apiUrl = `main/v1/laborers/work-history?${queryParams.toString()}`;

        return {
          url: apiUrl,
          method: 'GET',
        };
      },
      transformResponse: (response) => response,
      providesTags: ['labourer'],
    }),
    deleteLabourer: builder.mutation({
      query: (body) => ({
        url: `main/v1/laborers/delete`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['labourer'],
    }),
  }),
});

export const { useGetLabourerQuery, useCreateLabourerMutation, useUpdateLabourerMutation, useGetLabourerByIDQuery, useGetLabourersWorkHistoryQuery, useGetAllLabourerMutation,useGetAllLabourersWorkHistoryMutation, useDeleteLabourerMutation } = labourerApi;
