import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from '../../utils/baseQueryFn';


export const dataApi = createApi({
    reducerPath: "dataApi",
    baseQuery,
    endpoints: (builder) => ({
        getUsersData: builder.query({
            query: () => ({
                url: `main/v1/users/data`,
                method: "GET",
            }),
            transformResponse: (response) => response,
            providesTags: ["staff"],
        }),
        getStaffsData: builder.query({
            query: () => ({
                url: `main/v1/staffs/data`,
                method: "GET",
            }),
            transformResponse: (response) => response,
        }),
        getActivitylogData: builder.query({
            query: () => ({
                url: `main/v1/activity-log/data`,
                method: "GET",
            }),
            transformResponse: (response) => response,
        }),
        getRolesData: builder.query({
            query: () => ({
                url: `main/v1/roles/data`,
                method: "GET",
            }),
            transformResponse: (response) => response,
        }),
        uploadImage: builder.mutation({
            query: ({ body, model }) => ({
                url: `main/v1/document/upload?model_name=${model}`,
                method: "POST",
                body
            }),
        }),
        getSitesData: builder.query({
            query: () => ({
                url: `main/v1/sites/data`,
                method: "GET",
            }),
            transformResponse: (response) => response,
        }),
        getClientsData: builder.query({
            query: () => ({
                url: `main/v1/clients/data`,
                method: "GET",
            }),
            transformResponse: (response) => response,
        }),
        getPurchaseData: builder.query({
            query: () => ({
                url: `main/v1/shops/purchase-orders/data`,
                method: "GET",
            }),
            transformResponse: (response) => response,
        }),
        getLabourersData: builder.query({
            query: () => ({
                url: `main/v1/laborers/data`,
                method: "GET",
            }),
            transformResponse: (response) => response,
        }),
        getTeamsData: builder.query({
            query: () => ({
                url: `main/v1/laborers/teams/data`,
                method: "GET",
            }),
            transformResponse: (response) => response,
        }),
        getShopsData: builder.query({
            query: () => ({
                url: `main/v1/shops/data`,
                method: "GET",
            }),
            transformResponse: (response) => response,
            providesTags: ["shop"],
        }),
        getWorkHistoryData: builder.query({
            query: () => ({
                url: `main/v1/laborers/work-history/data`,
                method: "GET",
            }),
            transformResponse: (response) => response,
        }),
        getAssignmentsData: builder.query({
            query: () => ({
                url: `main/v1/laborers/assignments/data`,
                method: "GET",
            }),
            transformResponse: (response) => response,
        }),
        checkPermission: builder.mutation({
            query: () => ({
                url: `auth/v1/users/sync`,
                method: "GET",
            }),
            transformResponse: (response) => response,
        }),
        getMaterialsData: builder.query({
            query: () => ({
                url: `main/v1/materials/data`,
                method: "GET",
            }),
            transformResponse: (response) => response,
        }),
    }),
});

export const { useGetUsersDataQuery, useGetStaffsDataQuery, useGetActivitylogDataQuery, useGetRolesDataQuery, useUploadImageMutation, useGetSitesDataQuery, useGetClientsDataQuery, useGetPurchaseDataQuery, useGetLabourersDataQuery, useGetTeamsDataQuery, useGetShopsDataQuery, useGetWorkHistoryDataQuery, useGetAssignmentsDataQuery,useCheckPermissionMutation,useGetMaterialsDataQuery } = dataApi;
