import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from '../../utils/baseQueryFn';


export const assignmentsApi = createApi({
    reducerPath: "assignmentsApi",
    baseQuery,
    endpoints: (builder) => ({
        getAssignments: builder.query({
            query: ({ page, limit, startDate, endDate, teamLeads }) => {

                const queryParams = new URLSearchParams({
                    page,
                    limit,
                });

                if (teamLeads.length > 0) {
                    queryParams.append('team_ids', JSON.stringify(teamLeads));
                }

                if (startDate) {
                    queryParams.append('start_date', startDate);
                }

                if (endDate) {
                    queryParams.append('end_date', endDate);
                }

                const apiUrl = `main/v1/laborers/assignments?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["assignments"],
        }),
        getAllAssignments: builder.mutation({
            query: ({ page, limit, startDate, endDate, teamLeads }) => {

                const queryParams = new URLSearchParams({
                    page,
                    limit,
                });

                if (teamLeads.length > 0) {
                    queryParams.append('team_ids', JSON.stringify(teamLeads));
                }

                if (startDate) {
                    queryParams.append('start_date', startDate);
                }

                if (endDate) {
                    queryParams.append('end_date', endDate);
                }

                const apiUrl = `main/v1/laborers/assignments?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["assignments"],
        }),
        createAssignments: builder.mutation({
            query: (body) => ({
                url: `main/v1/laborers/assignments/new`,
                method: "POST",
                body
            }),
            invalidatesTags: ["assignments"],
        }),
        updateAssignments: builder.mutation({
            query: (body) => ({
                url: `main/v1/laborers/assignments/edit`,
                method: "POST",
                body
            }),
            invalidatesTags: ["assignments"],
        }),
        getAssignmentsByID: builder.query({
            query: (id) => ({
                url: `main/v1/laborers/assignments/${id}`,
                method: "GET",
            }),
            transformResponse: (response) => response,
            providesTags: ["assignments"],
        }),
    }),
});

export const { useGetAssignmentsQuery, useCreateAssignmentsMutation, useUpdateAssignmentsMutation, useGetAssignmentsByIDQuery,useGetAllAssignmentsMutation } = assignmentsApi;

