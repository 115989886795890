import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../utils/baseQueryFn';

export const clientApi = createApi({
  reducerPath: 'clientApi',
  baseQuery,
  endpoints: (builder) => ({
    getClient: builder.query({
      query: ({ page, limit, search }) => {
        const queryParams = new URLSearchParams({
          page,
          limit,
        });

        if (search) {
          queryParams.append('search', search);
        }

        const apiUrl = `main/v1/clients?${queryParams.toString()}`;

        return {
          url: apiUrl,
          method: 'GET',
        };
      },
      transformResponse: (response) => response,
      providesTags: ['client'],
    }),
    getAllClient: builder.mutation({
      query: ({ page, limit, search }) => {
        const queryParams = new URLSearchParams({
          page,
          limit,
        });

        if (search) {
          queryParams.append('search', search);
        }

        const apiUrl = `main/v1/clients?${queryParams.toString()}`;

        return {
          url: apiUrl,
          method: 'GET',
        };
      },
      transformResponse: (response) => response,
      providesTags: ['client'],
    }),
    createClient: builder.mutation({
      query: (body) => ({
        url: `main/v1/clients/new`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['client'],
    }),
    updateClient: builder.mutation({
      query: (body) => ({
        url: `main/v1/clients/edit`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['client'],
    }),
    getClientByID: builder.query({
      query: (id) => ({
        url: `main/v1/clients/${id}`,
        method: 'GET',
      }),
      transformResponse: (response) => response,
      providesTags: ['client'],
    }),
    checkClientId: builder.mutation({
      query: (body) => ({
        url: `main/v1/clients/check-client-id`,
        method: 'POST',
        body,
      }),
    }),
    deleteClient: builder.mutation({
      query: (body) => ({
        url: `main/v1/clients/delete`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['client'],
    }),
  }),
});

export const {
  useGetClientQuery,
  useCreateClientMutation,
  useUpdateClientMutation,
  useGetClientByIDQuery,
  useCheckClientIdMutation,
  useGetAllClientMutation,
  useDeleteClientMutation,
} = clientApi;
