import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../utils/baseQueryFn';

export const siteApi = createApi({
  reducerPath: 'siteApi',
  baseQuery,
  endpoints: (builder) => ({
    getSite: builder.query({
      query: ({ page, limit, search, status, client }) => {
        const queryParams = new URLSearchParams({
          page,
          limit,
        });

        if (search) {
          queryParams.append('search', search);
        }

        if (status) {
          queryParams.append('status', status);
        }

                if (client.length > 0) {
                    queryParams.append('client_ids', JSON.stringify(client));
                }

                const apiUrl = `main/v1/sites?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["site"],
        }),
        getAllSite: builder.mutation({
            query: ({ page, limit, search, status, client }) => {

                const queryParams = new URLSearchParams({
                    page,
                    limit,
                });

                if (search) {
                    queryParams.append('search', search);
                }

                if (status) {
                    queryParams.append('status', status);
                }

                if (client.length > 0) {
                    queryParams.append('client_ids', JSON.stringify(client));
                }

        const apiUrl = `main/v1/sites?${queryParams.toString()}`;

        return {
          url: apiUrl,
          method: 'GET',
        };
      },
      transformResponse: (response) => response,
      providesTags: ['site'],
    }),
    createSite: builder.mutation({
      query: (body) => ({
        url: `main/v1/sites/new`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['site'],
    }),
    updateSite: builder.mutation({
      query: (body) => ({
        url: `main/v1/sites/edit`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['site'],
    }),
    getSiteByID: builder.query({
      query: (id) => ({
        url: `main/v1/sites/${id}`,
        method: 'GET',
      }),
      transformResponse: (response) => response,
      providesTags: ['site'],
    }),
    deleteSite: builder.mutation({
      query: (body) => ({
        url: `main/v1/sites/delete`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['site'],
    }),
  }),
});

export const {
  useGetSiteQuery,
  useCreateSiteMutation,
  useUpdateSiteMutation, 
  useGetSiteByIDQuery, useGetAllSiteMutation,
  useDeleteSiteMutation,
} = siteApi;
