import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../utils/baseQueryFn';

export const teamsApi = createApi({
  reducerPath: 'teamsApi',
  baseQuery,
  endpoints: (builder) => ({
    getTeams: builder.query({
      query: ({ page, limit, search }) => {

                const queryParams = new URLSearchParams({
                    page,
                    limit,
                });

                if (search) {
                    queryParams.append('search', search);
                }

                const apiUrl = `main/v1/laborers/teams?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["teams"],
        }),
        getAllTeams: builder.mutation({
            query: ({ page, limit, search }) => {
        const queryParams = new URLSearchParams({
          page,
          limit,
        });

        if (search) {
          queryParams.append('search', search);
        }

        const apiUrl = `main/v1/laborers/teams?${queryParams.toString()}`;

        return {
          url: apiUrl,
          method: 'GET',
        };
      },
      transformResponse: (response) => response,
      providesTags: ['teams'],
    }),
    createTeams: builder.mutation({
      query: (body) => ({
        url: `main/v1/laborers/teams/new`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['teams'],
    }),
    updateTeams: builder.mutation({
      query: (body) => ({
        url: `main/v1/laborers/teams/edit`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
      invalidatesTags: ['teams'],
    }),
    getTeamsByID: builder.query({
      query: (id) => ({
        url: `main/v1/laborers/teams/${id}`,
        method: 'GET',
      }),
      transformResponse: (response) => response,
      providesTags: ['teams'],
    }),
    deleteTeams: builder.mutation({
      query: (body) => ({
        url: `main/v1/laborers/teams/delete`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['teams'],
    }),
  }),
});

export const {
  useGetTeamsQuery,
  useCreateTeamsMutation,
  useUpdateTeamsMutation,
  useGetTeamsByIDQuery,useGetAllTeamsMutation,
  useDeleteTeamsMutation,
} = teamsApi;
