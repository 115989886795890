import PropTypes from 'prop-types';
// @mui
import Stack from '@mui/material/Stack';

// ----------------------------------------------------------------------


export default function AuthClassicLayout({ children, image, title }) {

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: 'auto',
        maxWidth: 400,
        px: { xs: 2 },
        py: { xs: 8 },
        textAlign: 'center',
        justifyContent: 'center',
      }}
    >
      {children}
    </Stack>
  );

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: '100vh',
      }}
    >
      {renderContent}
    </Stack>
  );
}

AuthClassicLayout.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string,
  title: PropTypes.string,
};
